import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getProvinces(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/province`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createProvince(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/province/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeProvince(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/province`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editProvince(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/province/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateProvince(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.put(`/province/${data.slug}`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteProvince(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/province/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    extractProvince(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/province-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
