import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getDistricts(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/district`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createDistrict(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/district/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeDistrict(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/district`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editDistrict(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/district/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateDistrict(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.put(`/district/${data.slug}`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteDistrict(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/district/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    extractDistrict(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/district-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
