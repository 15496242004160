import axios from "axios";

const state = {
    token: localStorage.getItem('token') || null,
}

const mutations = {
    token(state, token) {
        state.token = token
    },
}
const getters = {
    token(state) {
        return state.token
    },
}

const actions = {
    login(context, credentials) {
        return new Promise((resolve, reject) => {
            axios.post('/login', {
                email: credentials.email,
                password: credentials.password,
            })
                .then(response => {
                    const token = response.data.token
                    localStorage.setItem('token', token)
                    context.commit('token', token)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    logout(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post('/logout')
                    .then(response => {
                        localStorage.removeItem('token')
                        context.commit('token', null);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
