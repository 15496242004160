import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getPermissions(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/permission`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createPermission(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/permission/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storePermission(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/permission`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showPermission(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/permission/${data.slug}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editPermission(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/permission/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updatePermission(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.put(`/permission/${data.id}`, data.permissions)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deletePermission(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/permission/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
