import axios from "axios";
import i18n from "../../i18n";

const state = {
    locale: i18n.locale,
    logo: '',
    user: {},
    showFullLoader: false,
    currentMonth: null,
    currentFiscalYear: null,
    navigations: [],
    months: ['वैशाख', 'जेठ', 'असार', 'साउन', 'भदौ', 'असोज', 'कार्तिक', 'मंसिर', 'पुष', 'माघ', 'फागुन', 'चैत'],
}
const mutations = {
    locale(state, value) {
        state.locale = value
    },
    user(state, value) {
        state.user = value
    },
    showFullLoader(state, value) {
        state.showFullLoader = value
    },
    currentMonth(state, value) {
        state.currentMonth = value
    },
    currentFiscalYear(state, value) {
        state.currentFiscalYear = value
    },
    logo(state, value) {
        state.logo = value
    },
    months(state, value) {
        state.months = value
    },
    navigations(state, value) {
        state.navigations = value
    },
}
const getters = {
    locale: state => state.locale,
    user: state => state.user,
    showFullLoader: state => state.showFullLoader,
    currentMonth: state => state.currentMonth,
    currentFiscalYear: state => state.currentFiscalYear,
    logo: state => state.logo,
    months: state => state.months,
    navigations: state => state.navigations,
}
const actions = {
    getMyNavigations(context, params) {
        if (context.getters.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.token
            return new Promise((resolve, reject) => {
                axios.get(`/myNavigation`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
