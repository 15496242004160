import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getDepartments(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/department`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createDepartment(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/department/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeDepartment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/department`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showDepartment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/department/${data.slug}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editDepartment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/department/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateDepartment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/department/${data.slug}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteDepartment(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/department/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
