const Login = () => import (/* webpackChunkName: "common-group" */ './views/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'./views/Dashboard/Dashboard.vue');
const DomainIndex = () => import (/* webpackChunkName: "domain-group" */'./views/Domain/DomainIndex.vue');
const DomainForm = () => import (/* webpackChunkName: "domain-group" */'./views/Domain/DomainForm.vue');
const DomainView = () => import (/* webpackChunkName: "domain-group" */'./views/Domain/DomainView.vue');
const ModuleIndex = () => import (/* webpackChunkName: "module-group" */'./views/Module/ModuleIndex.vue');
const ModuleForm = () => import (/* webpackChunkName: "module-group" */'./views/Module/ModuleForm.vue');
const NavigationIndex = () => import (/* webpackChunkName: "module-group" */'./views/Navigation/NavigationIndex.vue');
const NavigationForm = () => import (/* webpackChunkName: "module-group" */'./views/Navigation/NavigationForm.vue');
const UserIndex = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserForm.vue');
const RoleIndex = () => import (/* webpackChunkName: "role-group" */'./views/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "role-group" */'./views/Role/RoleForm.vue');
const PermissionIndex = () => import (/* webpackChunkName: "role-group" */'./views/Permission/PermissionIndex.vue');
const PermissionForm = () => import (/* webpackChunkName: "role-group" */'./views/Permission/PermissionForm.vue');
const ProgramProgressIndex = () => import (/* webpackChunkName: "role-group" */'./views/ProgramProgress/ProgramProgressIndex.vue');
const ProgramProgressForm = () => import (/* webpackChunkName: "role-group" */'./views/ProgramProgress/ProgramProgressForm.vue');
const ProgramProgressArrange = () => import (/* webpackChunkName: "role-group" */'./views/ProgramProgress/ProgramProgressArrange.vue');
const ProvinceIndex = () => import (/* webpackChunkName: "group-province" */'./views/Province/ProvinceIndex.vue');
const ProvinceForm = () => import (/* webpackChunkName: "group-province" */'./views/Province/ProvinceForm.vue');
const DistrictIndex = () => import (/* webpackChunkName: "group-province" */'./views/District/DistrictIndex.vue');
const DistrictForm = () => import (/* webpackChunkName: "group-province" */'./views/District/DistrictForm.vue');
const MunicipalityIndex = () => import (/* webpackChunkName: "group-province" */'./views/Municipality/MunicipalityIndex.vue');
const MunicipalityForm = () => import (/* webpackChunkName: "group-province" */'./views/Municipality/MunicipalityForm.vue');
const DepartmentIndex = () => import (/* webpackChunkName: "group-province" */'./views/Department/DepartmentIndex.vue');
const DepartmentForm = () => import (/* webpackChunkName: "group-province" */'./views/Department/DepartmentForm.vue');

const LakePondIndex = () => import (/* webpackChunkName: "group-river" */'./views/LakePond/LakePondIndex.vue');
const LakePondForm = () => import (/* webpackChunkName: "group-river" */'./views/LakePond/LakePondForm.vue');
const LakePondReport = () => import (/* webpackChunkName: "group-river" */'./views/LakePond/LakePondReport.vue');
const RiverSystemIndex = () => import (/* webpackChunkName: "group-river" */'./views/RiverSystem/RiverSystemIndex.vue');
const RiverSystemForm = () => import (/* webpackChunkName: "group-river" */'./views/RiverSystem/RiverSystemForm.vue');
const RiverSystemReport = () => import (/* webpackChunkName: "group-river" */'./views/RiverSystem/RiverSystemReport.vue');

const FeedbackIndex = () => import (/* webpackChunkName: "group-river" */'./views/Feedback/FeedbackIndex.vue');
const FeedbackForm = () => import (/* webpackChunkName: "group-river" */'./views/Feedback/FeedbackForm.vue');
const FeedbackView = () => import (/* webpackChunkName: "group-river" */'./views/Feedback/FeedbackView.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
        }
    },

    //Domains
    {
        path: '/domains',
        name: 'domain-index',
        component: DomainIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/domains/create',
        name: 'domain-create',
        component: DomainForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/domains/:slug/edit',
        name: 'domain-edit',
        params: true,
        component: DomainForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/domains/:slug/view',
        name: 'domain-view',
        params: true,
        component: DomainView,
        meta: {
            requiresAuth: true,
        }
    },

    //Module Management
    {
        path: '/modules',
        name: 'module-index',
        component: ModuleIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/modules/create',
        name: 'module-create',
        component: ModuleForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/modules/:slug/edit',
        name: 'module-edit',
        params: true,
        component: ModuleForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Navigation Management
    {
        path: '/navigations',
        name: 'navigation-index',
        component: NavigationIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/navigations/create',
        name: 'navigation-create',
        component: NavigationForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/navigations/:id/edit',
        name: 'navigation-edit',
        params: true,
        component: NavigationForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Users Management
    {
        path: '/users',
        name: 'user-index',
        params: true,
        component: UserIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        params: true,
        component: UserForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users/:username/edit',
        name: 'user-edit',
        params: true,
        component: UserForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Role Management
    {
        path: '/roles',
        name: 'role-index',
        params: true,
        component: RoleIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'role-create',
        params: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:slug/edit',
        name: 'role-edit',
        params: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Permission Management
    {
        path: '/permissions',
        name: 'permission-index',
        params: true,
        component: PermissionIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/permissions/create',
        name: 'permission-create',
        params: true,
        component: PermissionForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/permissions/:id/edit',
        name: 'permission-edit',
        params: true,
        component: PermissionForm,
        meta: {
            requiresAuth: true,
        }
    },
    //Program Progress Management
    {
        path: '/program-progress',
        name: 'program-progress-index',
        params: true,
        component: ProgramProgressIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/create',
        name: 'program-progress-create',
        params: true,
        component: ProgramProgressForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/edit',
        name: 'program-progress-edit',
        params: true,
        component: ProgramProgressForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/arrange',
        name: 'program-progress-arrange',
        params: true,
        component: ProgramProgressArrange,
        meta: {
            requiresAuth: true,
        }
    },

    //Province, District, Municipality Management
    {
        path: '/provinces',
        name: 'province-index',
        component: ProvinceIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/provinces/create',
        name: 'province-create',
        component: ProvinceForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/provinces/:slug/edit',
        name: 'province-edit',
        component: ProvinceForm,
        meta: {
            requiresAuth: true,
        }
    },

    //District
    {
        path: '/districts',
        name: 'district-index',
        component: DistrictIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/districts/create',
        name: 'district-create',
        component: DistrictForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/districts/:slug/edit',
        name: 'district-edit',
        component: DistrictForm,
        meta: {
            requiresAuth: true,
        }
    },
    //Municipality
    {
        path: '/municipalities',
        name: 'municipality-index',
        component: MunicipalityIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/municipalities/create',
        name: 'municipality-create',
        component: MunicipalityForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/municipalities/:slug/edit',
        name: 'municipality-edit',
        component: MunicipalityForm,
        meta: {
            requiresAuth: true,
        },
    },

    //Department
    {
        path: '/departments',
        name: 'department-index',
        component: DepartmentIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/departments/create',
        name: 'department-create',
        component: DepartmentForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/departments/:slug/edit',
        name: 'department-edit',
        component: DepartmentForm,
        meta: {
            requiresAuth: true,
        },
    },


    //LakePond
    {
        path: '/lakeponds',
        name: 'lakepond-index',
        component: LakePondIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/lakeponds/create',
        name: 'lakepond-create',
        component: LakePondForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/lakeponds/:id/edit',
        name: 'lakepond-edit',
        component: LakePondForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/lakeponds/report',
        name: 'lakepond-report',
        component: LakePondReport,
        meta: {
            requiresAuth: true,
        }
    },

    //River
    {
        path: '/rivers',
        name: 'river-index',
        component: RiverSystemIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/rivers/create',
        name: 'river-create',
        component: RiverSystemForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/rivers/:id/edit',
        name: 'river-edit',
        component: RiverSystemForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/rivers/report',
        name: 'river-report',
        component: RiverSystemReport,
        meta: {
            requiresAuth: true,
        }
    },

    //River
    {
        path: '/feedbacks',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/create',
        name: 'feedback-create',
        component: FeedbackForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/:id/edit',
        name: 'feedback-edit',
        component: FeedbackForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/:id/show',
        name: 'feedback-view',
        component: FeedbackView,
        meta: {
            requiresAuth: true,
        }
    },
];

export default routes
