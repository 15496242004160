import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getDomains(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/domain`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createDomain(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/domain/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeDomain(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/domain`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showDomain(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/domain/${data.slug}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editDomain(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/domain/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateDomain(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/domain/${data.slug}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteDomain(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/domain/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateDomainNavigationOrder(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/domain-navigation-order`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
